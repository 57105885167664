$primary: #2b6ea0;

:root {
  --nav-width: 92px;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --z-fixed: 100;
}

.nav_name {
  font-size: var(--small-font-size);
}

.nav_brand {
  display: inline-flex;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.nav_toggle {
  font-size: 1.35rem;
  // padding: 0.75rem;
  cursor: pointer;
}

.nav_link:hover {
  background-color: var(--primary-color);
}

.nav_icon {
  font-size: 1.25rem;
}

.nav_name {
  font-size: var(--small-font-size);
}

.expander {
  width: calc(var(--nav-width) + 9.25rem);
}

.collapse {
  grid-template-columns: 20px max-content 1fr;
}

.collapse_link {
  justify-self: flex-end;
  transition: 0.5s;
}

.collapse_menu {
  display: none;
  padding: 0.75rem 2.25rem;
}

.collapse_sub_link {
  color: var(--sub-color);
  font-size: var(--small-font-size);
}

.collapse_sub_link:hover {
  color: var(--white-color);
}
.showCollapse {
  display: block;
  transition: 5s;
}

.rotate {
  transform: rotate(180deg);
  transition: 5s;
}
//Navbar

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.auth-screen {
  background: #c8c9cc;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}
.btn-flat {
  border-radius: 0;
}
.color-primary {
  background-color: #2b6ea0;
  border-color: #2b6ea0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-primary {
  background-color: #2b6ea0;
  border-color: #2b6ea0;
}
.btn-primary:hover {
  background-color: #2b6ea0;
  border-color: #2b6ea0;
}

a {
  color: #2b6ea0;
  text-decoration: none;
}
.auth-screen-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}
.user-panel {
  height: 150px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
  margin: 20px;
  object-fit: cover;
  object-position: center right;
}

.auth-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2b6ea0;
  border-radius: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.box {
  padding: 1em;
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #fdfdfd;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

.box-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.bt-0 {
  border-top: 0;
}
.box-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.box-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.primary-screen {
  background-color: #f1f1f1;
}

.file-upload {
  padding: 1.5rem;
  border: 2px dotted #d2d2d6;
  transition: border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0.25rem;
  position: relative;
  display: grid;
}
.align-vertical {
  display: flex;
  align-items: center;
}
.file-image {
  height: 100px;
  width: 100px;
  object-fit: contain;
  border-radius: 4px;
}

.file-list {
  border: 2px dotted #d2d2d6;
  border-radius: 0.25rem;
}
.tab-03 {
  flex-wrap: nowrap;
  border: none;
  border-bottom: 1px solid #ebebeb;
}
.tab-03 li:not(:last-child) {
  margin-right: 1.5rem;
}
.nav {
  list-style: none;
}

.tab-03 li .nav-link {
  background-color: transparent;
  white-space: nowrap;
  border: none;
  color: #787885;
  cursor: pointer;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-link {
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.tab-03 li .nav-link.active {
  // color: #fff;
  color: $primary;
  background: none;
  font-weight: 600;
  // border-bottom: 3px solid #fff;
  border-bottom: 3px solid $primary;
}

.transaction-list-item > h4 {
  font-size: 1.25rem;
}
.transaction-list-item,
h5 {
  font-size: 1rem;
}
.tab-scrollable {
  overflow-y: scroll;
}
.otp-field {
  display: flex;
}

.otp-field input {
  width: 65px;
  height: 60px;
  margin: 3px;
  font-size: 25px;
  text-align: center;
}

.tab-scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar-close {
  width: 5.5rem;
}
